// Modules
import * as React from "react";

// Style
import "./companyIncorporation.scss";
import globeIcon from "./img/global.png";

// Components
import Hero, {
    IHeroProps,
    headerStyle
} from "src/components/Organisms/Hero/hero";
import Subscription from "src/components/Organisms/Subscription/subscription";
import CustomButton from "src/components/Atoms/CustomButton/customButton";
import { Checkmark } from "./img/checkMark";
import { CrossMark } from "./img/CrossMark";
import { BsXCircle } from "@react-icons/all-files/bs/BsXCircle";
import { BiCheckCircle } from "@react-icons/all-files/bi/BiCheckCircle";
import { UncontrolledCollapse } from "reactstrap";

import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
// Data
import { faqIncorporation } from "src/data/faqs";
import { t } from "i18next";

const heroProps: IHeroProps = {
    style: headerStyle(require("src/components/Organisms/images/banner/conference.jpg")),
    header: "Company incorporation",
    subLine:
        "We assist our clients in company incorporations and consultation services on business maintainance and upkeeping."
};

interface IProps extends WithTranslation {
    prop: any;
  }
  
  interface IState {
    state: any;
  }

const Pricing = ({},{
    prop,
}: IProps) => {
    const { t } = useTranslation('info');
return (
    <div className="container-fluid section-padding-tb96">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 pl-3 pr-3">
                    <div className="card shadow-sm">
                        <div className="card-header">
                            <div className="card-header__title">
                                <h3 className="text-mild">{t(`companyIncorporation.seed.title`)}</h3>
                            </div>
                            <div className="card-header__tag bg-theme-mild">
                                <h2 className="text-light">{t(`companyIncorporation.seed.price`)}</h2>
                                <span className="text-light mb-3">
                                {t(`companyIncorporation.seed.description`)}
                                </span>
                                <CustomButton
                                    text={t(`companyIncorporation.button`)}
                                    link="/incorporation/seed"
                                    variation="theme"
                                />
                            </div>
                        </div>
                        <div className="card-body">
                            <ul>
                                <li className="fill-mild">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj1`)}
                                    </span>
                                </li>
                                <li className="fill-mild">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj2`)}
                                    </span>
                                </li>
                                <li className="fill-mild">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj6`)}
                                    </span>
                                </li>
                                <li className="inact">
                                    <BsXCircle size={25} style={{marginRight:"15px"}}/>
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj4`)}
                                    </span>
                                </li>
                                <li className="inact">
                                    <BsXCircle size={25} style={{marginRight:"15px"}}/>
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj5`)}
                                    </span>
                                </li>
                                <li className="inact">
                                    <BsXCircle size={25} style={{marginRight:"15px"}}/>
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj6`)}
                                    </span>
                                </li>
                                <li className="inact">
                                    <BsXCircle size={25} style={{marginRight:"15px"}}/>
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj7`)}
                                    </span>
                                </li>
                                <li className="inact">
                                    <BsXCircle size={25} style={{marginRight:"15px"}}/>
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj8`)}
                                    </span>
                                </li>
                                <li className="inact">
                                    <BsXCircle size={25} style={{marginRight:"15px"}}/>
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj9`)}
                                    </span>
                                </li>
                                <li className="inact">
                                    <BsXCircle size={25} style={{marginRight:"15px"}}/>
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj10`)}
                                    </span>
                                </li>
                                <li className="inact">
                                    <BsXCircle size={25} style={{marginRight:"15px"}}/>
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj11`)}
                                    </span>
                                </li>
                                <li className="inact">
                                    <BsXCircle size={25} style={{marginRight:"15px"}}/>
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj12`)}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 pl-3 pr-3">
                    <div className="card shadow-sm">
                        <div className="card-header">
                            <div className="card-header__title">
                                <h3 className="text-medium">{t(`companyIncorporation.sprout.title`)}</h3>
                            </div>
                            <div className="card-header__tag bg-theme-medium">
                                <h2 className="text-light">{t(`companyIncorporation.sprout.price`)}</h2>
                                <span className="text-light mb-3">
                                {t(`companyIncorporation.sprout.description`)}
                                </span>
                                <CustomButton
                                    text={t(`companyIncorporation.button`)}
                                    link="/incorporation/sprout"
                                    variation="theme"
                                />

                            </div>
                        </div>
                        <div className="card-body">
                            <ul>
                                <li className="fill-medium">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj1`)}
                                    </span>
                                </li>
                                <li className="fill-medium">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj2`)}
                                    </span>
                                </li>
                                <li className="fill-medium">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj3`)}
                                    </span>
                                </li>
                                <li className="fill-medium">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj4`)}
                                    </span>
                                </li>
                                <li className="fill-medium">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj5`)}
                                    </span>
                                </li>
                                <li className="fill-medium">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj3`)}
                                    </span>
                                </li>
                                <li className="fill-medium">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj7`)}
                                    </span>
                                </li>
                                <li className="fill-medium">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj8`)}
                                    </span>
                                </li>
                                <li className="fill-medium">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj9`)}
                                    </span>
                                </li>
                                <li className="fill-medium">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj10`)}
                                    </span>
                                </li>
                                <li className="fill-medium">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj11`)}
                                    </span>
                                </li>
                                <li className="inact">
                                    <BsXCircle size={25} style={{marginRight:"15px"}}/>
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj12`)}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 pl-3 pr-3">
                    <div className="card shadow-sm">
                        <div className="card-header">
                            <div className="card-header__title">
                                <h3 className="text-intense">{t(`companyIncorporation.grow.title`)}</h3>
                            </div>
                            <div className="card-header__tag bg-theme-intense">
                                <h2 className="text-light">{t(`companyIncorporation.grow.price`)}</h2>
                                <span className="text-light mb-3">
                                {t(`companyIncorporation.grow.description`)}
                                </span>
                                <CustomButton
                                    text={t(`companyIncorporation.button`)}
                                    link="/incorporation/grow"
                                    variation="theme"
                                />
                            </div>
                        </div>
                        <div className="card-body">
                            <ul>
                                <li className="fill-intense">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj1`)}
                                    </span>
                                </li>
                                <li className="fill-intense">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj2`)}
                                    </span>
                                </li>
                                <li className="fill-intense">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj3`)}
                                    </span>
                                </li>
                                <li className="fill-intense">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj4`)}
                                    </span>
                                </li>
                                <li className="fill-intense">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj5`)}
                                    </span>
                                </li>
                                <li className="fill-intense">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj6`)}
                                    </span>
                                </li>
                                <li className="fill-intense">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj7`)}
                                    </span>
                                </li>
                                <li className="fill-intense">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj8`)}
                                    </span>
                                </li>
                                <li className="fill-intense">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj9`)}
                                    </span>
                                </li>
                                <li className="fill-intense">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj10`)}
                                    </span>
                                </li>
                                <li className="fill-intense">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj11`)}
                                    </span>
                                </li>
                                <li className="fill-intense">
                                    <Checkmark />
                                    <span className="body-short-texts">
                                        {t(`companyIncorporation.obj12`)}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)};
const ServiceBox = ({},{
    prop,
}: IProps) => {
    
    const { t } = useTranslation('info');
    return (
     <div className="container-fluid section-padding-tb96">
         <div className="container">
             <h2 className="section-header all-cap">
                 {t(`companyIncorporation.otherIncorporation.title`)}
                 <bdi className="section-header all-cap text-highlight">
                     {" "}
                     {t(`companyIncorporation.otherIncorporation.subTitle`)}
                 </bdi>
             </h2>
             <div className="row">
                 <div className="col-sm-6 col-lg-3 icon-text-container">
                     <img
                         className="icon-text-container__img"
                         src={globeIcon}
                         alt="Globe icon"
                     />
                     <span className="body-short-texts all-cap">
                     {t(`companyIncorporation.otherIncorporation.HK LIMITED COMPANY`)}
                     </span>
                 </div>
                 <div className="col-sm-6 col-lg-3 icon-text-container">
                     <img
                         className="icon-text-container__img"
                         src={globeIcon}
                         alt="Globe icon"
                     />
                     <span className="body-short-texts all-cap">
                     {t(`companyIncorporation.otherIncorporation.HK SOLE PROPRIETORSHIP`)}
                     </span>
                 </div>
                 <div className="col-sm-6 col-lg-3 icon-text-container">
                     <img
                         className="icon-text-container__img"
                         src={globeIcon}
                         alt="Globe icon"
                     />
                     <span className="body-short-texts all-cap">
                     {t(`companyIncorporation.otherIncorporation.HK BRANCH OFFICE`)}
                     </span>
                 </div>
                 <div className="col-sm-6 col-lg-3 icon-text-container">
                     <img
                         className="icon-text-container__img"
                         src={globeIcon}
                         alt="Globe icon"
                     />
                     <span className="body-short-texts all-cap">
                     {t(`companyIncorporation.otherIncorporation.HK REPRESENTATIVE OFFICE`)}
                     </span>
                 </div>
                 <div className="col-sm-6 col-lg-3 icon-text-container">
                     <img
                         className="icon-text-container__img"
                         src={globeIcon}
                         alt="Globe icon"
                     />
                     <span className="body-short-texts all-cap">
                     {t(`companyIncorporation.otherIncorporation.BVI INCORPORATION`)}
                     </span>
                 </div>
                 <div className="col-sm-6 col-lg-3 icon-text-container">
                     <img
                         className="icon-text-container__img"
                         src={globeIcon}
                         alt="Globe icon"
                     />
                     <span className="body-short-texts all-cap">
                     {t(`companyIncorporation.otherIncorporation.SAMOA INCORPORATION`)}
                     </span>
                 </div>
                 <div className="col-sm-6 col-lg-3 icon-text-container">
                     <img
                         className="icon-text-container__img"
                         src={globeIcon}
                         alt="Globe icon"
                     />
                     <span className="body-short-texts all-cap">
                     {t(`companyIncorporation.otherIncorporation.SEYCHELLES INCORPORATION`)}
                     </span>
                 </div>
                 <div className="col-sm-6 col-lg-3 icon-text-container">
                     <img
                         className="icon-text-container__img"
                         src={globeIcon}
                         alt="Globe icon"
                     />
                     <span className="body-short-texts all-cap">
                     {t(`companyIncorporation.otherIncorporation.CHINA WFOE INCORPORATION`)}
                     </span>
                 </div>
             </div>
         </div>
     </div>
 );
}
const FAQ = (props: {
    qaArray: Array<{ question: string; answer: string }>;
},{
    prop,
}: IProps) => {
    // console.log(props.qaArray.map((sets, index) => {console.log(sets.answer,index)}))
    const { t } = useTranslation('info');
    return (
        <div className="container-fluid section-padding-tb96">
            <div className="container">
                <h2 className="section-header all-cap">
                {t(`companyIncorporation.Q-AND-A.title`)}
                    <bdi className="section-header all-cap text-highlight">
                        {" "}
                        {t(`companyIncorporation.Q-AND-A.subTitle`)}
                    </bdi>
                </h2>
                <ul className="list-group list-group-flush">
                    {props.qaArray.map((sets, index) => (
                        <li className="list-group-item">
                            <h4
                                className="overline-header-bold"
                                id={"faq" + index}
                            >
                                {t(`companyIncorporation.Q-AND-A.Q.${index}`)}
                            </h4>
                            <UncontrolledCollapse toggler={"#faq" + index}>
                                <p className="ml-3 collaps-text">
                                {t(`companyIncorporation.Q-AND-A.A.${index}`)}
                                </p>
                            </UncontrolledCollapse>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
const TwoOptions = () => (
    <div className="container-fluid section-padding-tb96">
        {/* <div className="container">
            <h2 className="section-header all-cap">
                <bdi className="section-header all-cap text-highlight">
                    Ready{" "}
                </bdi>
                or not
            </h2>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <div className="card border-0">
                        <div className="card-body p-5 d-flex flex-column justify-content-between">
                            <h3 className="card-title all-cap">
                                I want to <bdi className="text-alert">talk</bdi>
                            </h3>
                            <p className="card-text">
                                I’m not sure about what to do, I feel more
                                safter when I have my hands being hold onto
                                throughtout my journey of incorporating my
                                company in HK with Centre O.
                            </p>

                            <CustomButton
                                link="/"
                                text="Talk to us"
                                variation={"alertwarning"}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className=" card border-0">
                        <div className="card-body p-5 d-flex flex-column justify-content-between">
                            <h3 className="card-title all-cap">
                                I am{" "}
                                <bdi className="text-affirmative">
                                    convinced
                                </bdi>
                            </h3>
                            <p className="card-text">
                                The information is clear, and I’m readly
                                convinced by what I see on the website to
                                incorporate my company in Hong Kong with Centre
                                O. Let’s get this started.
                            </p>
                            <CustomButton
                                link="/incorporation/seed"
                                text="Let's get rolling"
                                variation={"affirmative"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </div>
);

export default class CompanyIncorporation extends React.PureComponent {
    
    public render() {
        return (
            <>
                <Hero {...heroProps} />
                <Pricing />
                <ServiceBox />
                <FAQ qaArray={faqIncorporation} />
                <TwoOptions />
                <Subscription />
            </>
        );
    }
}
